module.exports = [{
      plugin: require('/Users/fujiwarahiroyuki/my_business/gatsby-gashitsolution-netlify-cms/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('/Users/fujiwarahiroyuki/my_business/gatsby-gashitsolution-netlify-cms/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/Users/fujiwarahiroyuki/my_business/gatsby-gashitsolution-netlify-cms/src/cms/cms.js"},
    },{
      plugin: require('/Users/fujiwarahiroyuki/my_business/gatsby-gashitsolution-netlify-cms/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"none"},
    }]
